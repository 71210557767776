//Importing modules
import React, {useContext, useState, useReducer, useEffect } from "react";
import axios from "axios";
import ActiveCardCard from "./ActiveCardCard/ActiveCardCard"
import ErrorBoundary from "../../Utils/ErrorBoundary";
import AuthContext from "../../../context/auth-context"
import {useHistory} from "react-router-dom"
import { dataFetchReducer } from "../../Utils/requestUtils";
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import NewCardDialog from "./NewCardDialog/NewCardDialog";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import * as S from './styles'
const pagarme = require('pagarme')

//Defining ActiveCardInbox component
function ActiveCardInbox(props){

    const { t } = useTranslation()
    const texts = {
        card_number: t("card_number"),
        cardholder_name: t("cardholder_name"),
        expiery_date: t("expiery_date"),
        active_card: t("active_card"),
        inactive_card: t("inactive_card")
    }

    const [processingPayment, setProcessingPayment] = useState(false);
    
    const handleCardEncryption = (card) => {
        pagarme.client.connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY })
        .then(client => {
          return client.security.encrypt(card)
        })
        .then(card_hash => {
            axios.post('/dash/billing/card', { card_hash: card_hash }).then(response => {
                if (response.status === 200) {
                    setRefreshPage(!refreshPage)
                } else {
                    alert('Falha ao registrar cartão - Entre em contato com o time CaaS')
                }
            })
        })
        
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    
    const [transactionFailureSnackbar, setTransactionFailureSnackbar] = useState(false);
    
    const handleWalletPayment = () => {
        setProcessingPayment(true)
        axios.post('/dash/billing/payment').then(response => {
            if (response.status === 200 || response.status === 204) {
                setRefreshPage(!refreshPage);
            }
        }).catch(error => {
            console.log(error)
            if (error.response.status < 500) {
                setTransactionFailureSnackbar(true);
                return;
            };
            alert("Falha interna na requisição, por favor entre em contato com a equipe QI CaaS.")
        })
        sleep(2000).then(() => setProcessingPayment(false));
    }

    const format_cost = (wallet_item_events_data) => {
        let value = 0;
        for (let i = 0; i < wallet_item_events_data.length; i++) {
            value = value + wallet_item_events_data[i].amount;
        }
        return "R$" + (value / 10000).toFixed(2).toString().replace('.', ',');
    }

    //Checking viewer role
    let history = useHistory()
    let roles = useContext(AuthContext).user_data.roles
    if (!roles.includes("billing_viewer")){
        history.push("")
    }

    //Defining state variable to control page refreshing
    const [refreshPage, setRefreshPage] = useState(false)

    //Defining reducer to control page state
    const [card, dispatchCard] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const [openNewCardDialog, setOpenNewCardDialog] = useState(false);

    const [wallet, dispatchWallet] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    const [walletItemEvents, dispatchWalletItemEvents] = useReducer(
        dataFetchReducer,
        {fetchedData: null, isLoading: true, isError: false} 
    )

    useEffect(() => {
        dispatchWalletItemEvents({type: "data_fetch_init"})
        if (wallet.fetchedData) {
            const timer = setTimeout(() => {
                axios.get(`/dash/companies/wallet_item_events?page_number=0&page_rows=1000&wallet_key=${wallet.fetchedData.wallet_key}`).then(response => {
                    if (response.status === 204) {
                        dispatchWalletItemEvents({
                            type: "data_fetch_success",
                            payload: {message: "Nenhuma carteira ativa foi encontrada."}
                        })
                    }
                    else {
                        dispatchWalletItemEvents({
                            type: "data_fetch_success",
                            payload: response.data
                        })
                    }
                }).catch(error => {
                    if ((error.response || {}).status === 403) dispatchWalletItemEvents({type: "data_fetch_failure_403"})
                    else if ((error.response || {}).status === 404) dispatchWalletItemEvents({type: "data_fetch_failure_404"})  
                    else dispatchWalletItemEvents({type: "data_fetch_failure"})    
                })	
            }, 500);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [wallet])

    useEffect(() => {
		dispatchWallet({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/companies/wallet').then(response => {
                if (response.status === 204) {
                    dispatchWallet({
                        type: "data_fetch_success",
                        payload: {message: "Nenhuma carteira ativa foi encontrada."}
                    })
                }
                else {
                    dispatchWallet({
                        type: "data_fetch_success",
                        payload: response.data
                    })
                }
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchWallet({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchWallet({type: "data_fetch_failure_404"})  
                else dispatchWallet({type: "data_fetch_failure"})    
			})	
        }, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [refreshPage])

    //Fetching data from dash_api
    useEffect(() => {
		dispatchCard({type: "data_fetch_init"})
		const timer = setTimeout(() => {
			axios.get('/dash/billing/card').then( response => {
                if (response.status === 204) {
                    dispatchCard({
                        type: "data_fetch_success",
                        payload: {message: "Nenhum cartão ativo foi encontrado."}
                    })
                }
                else {
                    dispatchCard({
                        type: "data_fetch_success",
                        payload: response.data
                    })
                }
			}).catch(error => {
                if ((error.response || {}).status === 403) dispatchCard({type: "data_fetch_failure_403"})
                else if ((error.response || {}).status === 404) dispatchCard({type: "data_fetch_failure_404"})  
                else dispatchCard({type: "data_fetch_failure"})    
			})	
        }, 500);
		return () => {
			clearTimeout(timer)
        }
    }, [refreshPage])

    //Rendering page after data was fetched
    if(card.fetchedData){
        if (card.fetchedData.message) {
            return (
                <S.Container>
                    <S.Heading>
                        <S.Title>{t("payments")}</S.Title>
                    </S.Heading>
                    <S.NoCardContainer>
                        <S.NotificationTitle>{t("no_card_notification")}</S.NotificationTitle>
                        <S.Message>{t("no_card_description")}</S.Message>
                        <S.AddCardButton onClick={() => setOpenNewCardDialog(true)}><S.ButtonText>{t("register_credit_card")}</S.ButtonText></S.AddCardButton>
                    </S.NoCardContainer>
                    <NewCardDialog open={openNewCardDialog} onSuccess={handleCardEncryption} onClose={() => setOpenNewCardDialog(false)} setRefreshPage={() => setRefreshPage(!refreshPage)}/>
                </S.Container>
            )
        }
        else {
            return (
                <S.Container>
                    <S.Heading>
                        <S.Title>
                            {t("payments")}
                        </S.Title>
                    </S.Heading>
                    <S.ActiveCardContainer>
                        <S.NotificationTitle>{t("registered_credit_card")}</S.NotificationTitle>
                        <ErrorBoundary>
                            <ActiveCardCard card={card.fetchedData} texts={texts} refresh={() => setRefreshPage(!refreshPage)}/>
                        </ErrorBoundary>
                        <S.AddCardButton onClick={() => setOpenNewCardDialog(true)}><S.ButtonText>{t("register_another_card")}</S.ButtonText></S.AddCardButton>
                    </S.ActiveCardContainer>
                    {wallet.fetchedData && wallet.fetchedData.wallet_status.enum === 'pending_payment' && walletItemEvents.fetchedData ?
                    <S.ActiveCardContainer>
                        <S.NotificationTitle>Pagamentos pendentes</S.NotificationTitle>
                        <S.Message>Para ativar sua carteira, realize o pagamento das pendências de plano e consumo de sua conta.</S.Message>
                        {processingPayment ? <S.AddCardButton gray onClick={() => {}}><S.ButtonText>{t('in_processing')}</S.ButtonText><CircularProgress style={{ width:"12px", height:"12px", margin:"auto", color:"white" }}/></S.AddCardButton>
                        :
                        <S.AddCardButton onClick={handleWalletPayment}><S.ButtonText>Realizar o pagamento:</S.ButtonText><S.ButtonText>{format_cost(walletItemEvents.fetchedData.data)}</S.ButtonText></S.AddCardButton>
                        }
                    </S.ActiveCardContainer>
                    : <></> }
                    {wallet.fetchedData && wallet.fetchedData.wallet_status.enum === 'frozen' && walletItemEvents.fetchedData ?
                    <S.ActiveCardContainer>
                        <S.NotificationTitle>Pagamentos pendentes</S.NotificationTitle>
                        <S.Message>Para reativar sua carteira, realize o pagamento dos consumos extras que foram realizados.</S.Message>
                        {processingPayment ? <S.AddCardButton gray onClick={() => {}}><S.ButtonText>{t('in_processing')}</S.ButtonText><CircularProgress style={{ width:"12px", height:"12px", margin:"auto", color:"white" }}/></S.AddCardButton>
                        :
                        <S.AddCardButton onClick={handleWalletPayment}><S.ButtonText>Realizar o pagamento: </S.ButtonText><S.ButtonText>{format_cost(walletItemEvents.fetchedData.data)}</S.ButtonText></S.AddCardButton>
                        }
                    </S.ActiveCardContainer>
                    : <></> }
                    <NewCardDialog open={openNewCardDialog} onSuccess={handleCardEncryption} onClose={() => setOpenNewCardDialog(false)} setRefreshPage={() => setRefreshPage(!refreshPage)}/>
                    <Snackbar
                        open={transactionFailureSnackbar}
                        autoHideDuration={12000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        onClose={() => setTransactionFailureSnackbar(false)}
                    >
                        <Alert variant="filled" severity="error">
                            {t('Sua transação foi recusada. Se isso se trata de um erro, entre em contato com a equipe de suporte QI Tech KYC.')}
                        </Alert>
                    </Snackbar>
                </S.Container>
            )
        }
    }
    //Rendering page when an error occurs while fetching data
    else if (card.isError){
		return (
            <S.Container>
            <S.Heading>
                <S.Title>{t("payments")}</S.Title>
            </S.Heading>
            <S.NoCardContainer>
                <S.NotificationTitle>{t("card_recovery_error_message")}</S.NotificationTitle>
                <S.Message>{card.errorMessage}</S.Message>
            </S.NoCardContainer>
        </S.Container>
		)
    }
    //Rendering page while data is being fetched
	else if (card.isLoading) {
		return(
            <S.Container>
            <S.Heading>
                <S.Title>{t("payments")}</S.Title>
            </S.Heading>
            <CircularProgress />
        </S.Container>
        )
    }
    else return null

}



//Exporting component
export default ActiveCardInbox
